<template>
  <div>
    <noscript v-if="form && form.gtm_code !== ''"><iframe :src="`https://www.googletagmanager.com/ns.html?id=${form.gtm_code}`" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    <Form v-if="(form && form.is_active)" :id="form.id" :formSettings="form" />
  </div>
</template>

<script>
import {mapActions} from 'vuex'

import Form from '@/components/Form'
export default {
  name: 'FormView',
  components: {
    Form,
  },
  data() {
    return {
      templates: null,
      form: null,
    }
  },
  created() {
    this.getFormTemplate().then((resp) => {
      this.templates = resp.data
      const defaultForm = this.templates.find(form => form.id === Number(this.$route.params.id))
      if(defaultForm) {
        this.form = defaultForm
        if(this.form.gtm_code !== '') {
          this.activateGtm(window,document,'script','dataLayer', this.form.gtm_code);
        }
      }
    })
  },
  destroyed() {
    if(this.form.gtm_code !== '') {
      document.getElementById('gtm').remove()
    }
  },
  methods: {
    ...mapActions({
      getFormTemplate: 'form/getFormTemplate',
    }),
    activateGtm(w,d,s,l,i){
      w[l]=w[l]||[];
      w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
      j.async=true;
      j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
      j.id='gtm'
      f.parentNode.insertBefore(j,f);
     },
  }
}
</script>

<style>

</style>
<template>
  <div v-if="ready">
    <div v-if="alert" class="columns is-mobile is-variable is-12 is-multiline">
      <div class="column is-12 alert-info">
        <h1>Formularz został wysłany.</h1>
        <p v-if="infoAfterSubmit && infoAfterSubmit.is_active">
          {{ infoAfterSubmit.content }}
        </p>
        <button class="button is-medium is-primary" @click="alert = !alert">
          zamknij
        </button>
      </div>
    </div>
    <ValidationObserver v-else ref="formObserver" v-slot="{ errors }">
      <form class="login" @submit.prevent="onSubmitScroll">
        <section class="section">
          <div class="ym-toptitle-box">
            <fixed-header :threshold="0" fixedClass="ym-toptitle-fixed">
              <div id="toptitle" class="level ym-toptitle">
                <div class="level-left">
                  <h2 class="title">{{ formSettings.name }}</h2>
                </div>
                <div class="buttons level-right">
                  <button
                    v-if="formSettings && formSettings.calendar_code !== ''"
                    class="button is-medium is-secondary"
                    type="button"
                    @click="isModalActive = !isModalActive"
                  >
                    kalendarz serwisowy
                  </button>
                </div>
              </div>
            </fixed-header>
          </div>
          <template v-if="inputs && inputs.length > 0">
            <template v-for="input in inputs">
              <template v-if="input.field_type === 'input' && input.is_active">
                <div
                  :class="
                    errors[input.field_name] &&
                    errors[input.field_name].length > 0
                      ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                      : 'columns is-mobile is-variable is-3 is-multiline'
                  "
                  :key="input.id"
                >
                  <div class="column ym-column is-12">
                    <div class="ym-label">
                      <label for="f_company_full">{{ input.name }}</label>
                    </div>
                  </div>
                  <div class="column ym-column is-12">
                    <div class="field">
                      <div class="control has-icons-right is-clearfix">
                        <ValidationProvider
                          :rules="input.is_required ? 'required' : ''"
                          :name="input.field_name"
                        >
                          <input
                            :key="input.id"
                            :name="input.field_name"
                            v-model="form[input.field_name]"
                            class="input is-medium"
                          />
                        </ValidationProvider>
                        <span
                          v-if="
                            errors[input.field_name] &&
                            errors[input.field_name].length > 0
                          "
                          class="help is-danger"
                          >{{ errors[input.field_name][0] }}</span
                        >
                        <b-notification
                          v-if="
                            input.field_placeholder !== '' &&
                            !form[input.field_name]
                          "
                          type="is-info is-light placeholder"
                          :closable="false"
                          >{{ input.field_placeholder }}</b-notification
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template
                v-if="input.field_type === 'textarea' && input.is_active"
              >
                <div
                  :class="
                    errors[input.field_name] &&
                    errors[input.field_name].length > 0
                      ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                      : 'columns is-mobile is-variable is-3 is-multiline'
                  "
                  :key="input.id"
                >
                  <div class="column ym-column is-12">
                    <div class="ym-label">
                      <label for="f_company_full">{{ input.name }}</label>
                    </div>
                  </div>
                  <div class="column ym-column is-12">
                    <div class="field">
                      <div class="control has-icons-right is-clearfix">
                        <ValidationProvider
                          :rules="input.is_required ? 'required' : ''"
                          :name="input.field_name"
                        >
                          <textarea
                            :key="input.id"
                            :name="input.field_name"
                            v-model="form[input.field_name]"
                            class="input is-medium"
                          />
                        </ValidationProvider>
                        <span
                          v-if="
                            errors[input.field_name] &&
                            errors[input.field_name].length > 0
                          "
                          class="help is-danger"
                          >{{ errors[input.field_name][0] }}</span
                        >
                        <b-notification
                          v-if="
                            input.field_placeholder !== '' &&
                            !form[input.field_name]
                          "
                          type="is-info is-light placeholder"
                          :closable="false"
                          >{{ input.field_placeholder }}</b-notification
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template
                v-if="input.field_type === 'checkbox' && input.is_active"
              >
                <div
                  :class="
                    errors[input.field_name] &&
                    errors[input.field_name].length > 0
                      ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                      : 'columns is-mobile is-variable is-3 is-multiline'
                  "
                  :key="input.id"
                >
                  <div class="column ym-column is-12">
                    <div class="ym-label">
                      <label for="f_company_full">{{ input.name }}</label>
                    </div>
                  </div>
                  <div class="column ym-column is-12">
                    <div class="field">
                      <div class="control has-icons-right is-clearfix">
                        <ValidationProvider
                          :rules="input.is_required ? 'required' : ''"
                          :name="input.field_name"
                        >
                          <b-checkbox
                            :key="input.id"
                            :name="input.field_name"
                            v-model="form[input.field_name]"
                            size="is-medium"
                            :native-value="1"
                            >{{ input.field_values }}</b-checkbox
                          >
                        </ValidationProvider>
                        <span
                          v-if="
                            errors[input.field_name] &&
                            errors[input.field_name].length > 0
                          "
                          class="help is-danger"
                          >{{ errors[input.field_name][0] }}</span
                        >
                        <b-notification
                          v-if="
                            input.field_placeholder !== '' &&
                            !form[input.field_name]
                          "
                          type="is-info is-light placeholder"
                          :closable="false"
                          >{{ input.field_placeholder }}</b-notification
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="input.field_type === 'radio' && input.is_active">
                <div
                  :class="
                    errors[input.field_name] &&
                    errors[input.field_name].length > 0
                      ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                      : 'columns is-mobile is-variable is-3 is-multiline'
                  "
                  :key="input.id"
                >
                  <div class="column ym-column is-12">
                    <div class="ym-label">
                      <label for="f_company_full">{{ input.name }}</label>
                    </div>
                  </div>
                  <div class="column ym-column is-12">
                    <div class="ym-rowbor">
                      <div class="level ym-rowbor-item">
                        <div class="ym-radios">
                          <label
                            v-for="(name, index) in input.field_values"
                            :key="index"
                          >
                            <ValidationProvider
                              :rules="input.is_required ? 'required' : ''"
                              :name="input.field_name"
                            >
                              <input
                                type="radio"
                                :name="input.field_name"
                                v-model="form[input.field_name]"
                                :value="name"
                              />
                              <span>{{ name }}</span>
                            </ValidationProvider>
                          </label>
                        </div>
                      </div>
                      <span
                        v-if="
                          errors[input.field_name] &&
                          errors[input.field_name].length > 0
                        "
                        class="help is-danger"
                        >{{ errors[input.field_name][0] }}</span
                      >
                      <b-notification
                        v-if="
                          input.field_placeholder !== '' &&
                          !form[input.field_name]
                        "
                        type="is-info is-light placeholder"
                        :closable="false"
                        >{{ input.field_placeholder }}</b-notification
                      >
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="input.field_type === 'select' && input.is_active">
                <div
                  :class="
                    errors[input.field_name] &&
                    errors[input.field_name].length > 0
                      ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                      : 'columns is-mobile is-variable is-3 is-multiline'
                  "
                  :key="input.id"
                >
                  <div class="column ym-column is-12">
                    <div class="ym-label">
                      <label for="f_company_full">{{ input.name }}</label>
                    </div>
                  </div>
                  <div class="column ym-column is-12">
                    <div class="field">
                      <div class="control has-icons-right is-clearfix">
                        <ValidationProvider
                          :rules="input.is_required ? 'required' : ''"
                          :name="input.field_name"
                        >
                          <multiselect
                            :name="input.field_name"
                            v-model="form[input.field_name]"
                            :options="input.field_values"
                            :key="input.id"
                            placeholder="Wybierz"
                          ></multiselect>
                        </ValidationProvider>
                        <span
                          v-if="
                            errors[input.field_name] &&
                            errors[input.field_name].length > 0
                          "
                          class="help is-danger"
                          >{{ errors[input.field_name][0] }}</span
                        >
                        <b-notification
                          v-if="
                            input.field_placeholder !== '' &&
                            !form[input.field_name]
                          "
                          type="is-info is-light placeholder"
                          :closable="false"
                          >{{ input.field_placeholder }}</b-notification
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template
                v-if="input.field_type === 'multiselect' && input.is_active"
              >
                <div
                  :class="
                    errors[input.field_name] &&
                    errors[input.field_name].length > 0
                      ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                      : 'columns is-mobile is-variable is-3 is-multiline'
                  "
                  :key="input.id"
                >
                  <div class="column ym-column is-12">
                    <div class="ym-label">
                      <label for="f_company_full">{{ input.name }}</label>
                    </div>
                  </div>
                  <div class="column ym-column is-12">
                    <div class="field">
                      <div class="control has-icons-right is-clearfix">
                        <ValidationProvider
                          :rules="input.is_required ? 'required' : ''"
                          :name="input.field_name"
                        >
                          <multiselect
                            :name="input.field_name"
                            v-model="form[input.field_name]"
                            :options="input.field_values"
                            :key="input.id"
                            :multiple="true"
                            placeholder="Wybierz, możesz kilka"
                          ></multiselect>
                        </ValidationProvider>
                        <span
                          v-if="
                            errors[input.field_name] &&
                            errors[input.field_name].length > 0
                          "
                          class="help is-danger"
                          >{{ errors[input.field_name][0] }}</span
                        >
                        <b-notification
                          v-if="
                            input.field_placeholder !== '' &&
                            !form[input.field_name]
                          "
                          type="is-info is-light placeholder"
                          :closable="false"
                          >{{ input.field_placeholder }}</b-notification
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template
                v-if="input.field_type === 'datepicker' && input.is_active"
              >
                <div
                  :class="
                    errors[input.field_name] &&
                    errors[input.field_name].length > 0
                      ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                      : 'columns is-mobile is-variable is-3 is-multiline'
                  "
                  :key="input.id"
                >
                  <div class="column ym-column is-12">
                    <div class="ym-label">
                      <label for="f_company_full">{{ input.name }}</label>
                    </div>
                  </div>
                  <div class="column ym-column is-12">
                    <div class="field">
                      <div class="control has-icons-right is-clearfix">
                        <ValidationProvider
                          :rules="input.is_required ? 'required' : ''"
                          :name="input.field_name"
                        >
                          <b-datepicker
                            :mobile-native="false"
                            :name="input.field_name"
                            v-model="form[input.field_name]"
                            icon="calendar-today"
                            :first-day-of-week="1"
                            position="is-top-right"
                            trap-focus
                          >
                          </b-datepicker>
                        </ValidationProvider>
                        <span
                          v-if="
                            errors[input.field_name] &&
                            errors[input.field_name].length > 0
                          "
                          class="help is-danger"
                          >{{ errors[input.field_name][0] }}</span
                        >
                        <b-notification
                          v-if="
                            input.field_placeholder !== '' &&
                            !form[input.field_name]
                          "
                          type="is-info is-light placeholder"
                          :closable="false"
                          >{{ input.field_placeholder }}</b-notification
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template
                v-if="input.field_type === 'datetimepicker' && input.is_active"
              >
                <div
                  :class="
                    errors[input.field_name] &&
                    errors[input.field_name].length > 0
                      ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                      : 'columns is-mobile is-variable is-3 is-multiline'
                  "
                  :key="input.id"
                >
                  <div class="column ym-column is-12">
                    <div class="ym-label">
                      <label for="f_company_full">{{ input.name }}</label>
                    </div>
                  </div>
                  <div class="column ym-column is-12">
                    <div class="field">
                      <div class="control has-icons-right is-clearfix">
                        <ValidationProvider
                          :rules="input.is_required ? 'required' : ''"
                          :name="input.field_name"
                        >
                          <b-datetimepicker
                            :mobile-native="false"
                            :name="input.field_name"
                            v-model="form[input.field_name]"
                            icon="calendar-today"
                            :first-day-of-week="1"
                            position="is-top-right"
                            trap-focus
                          >
                          </b-datetimepicker>
                        </ValidationProvider>
                        <span
                          v-if="
                            errors[input.field_name] &&
                            errors[input.field_name].length > 0
                          "
                          class="help is-danger"
                          >{{ errors[input.field_name][0] }}</span
                        >
                        <b-notification
                          v-if="
                            input.field_placeholder !== '' &&
                            !form[input.field_name]
                          "
                          type="is-info is-light placeholder"
                          :closable="false"
                          >{{ input.field_placeholder }}</b-notification
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template
                v-if="input.field_type === 'timepicker' && input.is_active"
              >
                <div
                  :class="
                    errors[input.field_name] &&
                    errors[input.field_name].length > 0
                      ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                      : 'columns is-mobile is-variable is-3 is-multiline'
                  "
                  :key="input.id"
                >
                  <div class="column ym-column is-12">
                    <div class="ym-label">
                      <label for="f_company_full">{{ input.name }}</label>
                    </div>
                  </div>
                  <div class="column ym-column is-12">
                    <div class="field">
                      <div class="control has-icons-right is-clearfix">
                        <ValidationProvider
                          :rules="input.is_required ? 'required' : ''"
                          :name="input.field_name"
                        >
                          <b-timepicker
                            :mobile-native="false"
                            :name="input.field_name"
                            v-model="form[input.field_name]"
                            icon="calendar-today"
                            :first-day-of-week="1"
                            position="is-top-right"
                            trap-focus
                          >
                          </b-timepicker>
                        </ValidationProvider>
                        <span
                          v-if="
                            errors[input.field_name] &&
                            errors[input.field_name].length > 0
                          "
                          class="help is-danger"
                          >{{ errors[input.field_name][0] }}</span
                        >
                        <b-notification
                          v-if="
                            input.field_placeholder !== '' &&
                            !form[input.field_name]
                          "
                          type="is-info is-light placeholder"
                          :closable="false"
                          >{{ input.field_placeholder }}</b-notification
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="input.field_type === 'file' && input.is_active">
                <div
                  :class="
                    errors[input.field_name] &&
                    errors[input.field_name].length > 0
                      ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                      : 'columns is-mobile is-variable is-3 is-multiline'
                  "
                  :key="input.id"
                >
                  <div class="column ym-column is-12">
                    <div class="ym-label">
                      <label for="f_company_full">{{ input.name }}</label>
                    </div>
                  </div>
                  <div class="column ym-column is-12">
                    <div class="field">
                      <div class="control has-icons-right is-clearfix">
                        <ValidationProvider
                          :rules="input.is_required ? 'required' : ''"
                          :name="input.field_name"
                        >
                          <b-upload
                            v-model="temp[`${input.field_name}_temp`]"
                            @input="fileToBase64($event, input.field_name)"
                            class="file-label"
                            :key="input.id"
                          >
                            <span class="file-cta">
                              <span class="file-label">Dodaj plik</span>
                            </span>
                            <span
                              class="file-name"
                              v-if="temp[`${input.field_name}_temp`]"
                            >
                              {{ temp[`${input.field_name}_temp`].name }}
                            </span>
                          </b-upload>
                        </ValidationProvider>
                        <span
                          v-if="
                            errors[input.field_name] &&
                            errors[input.field_name].length > 0
                          "
                          class="help is-danger"
                          >{{ errors[input.field_name][0] }}</span
                        >
                        <b-notification
                          v-if="
                            input.field_placeholder !== '' &&
                            !form[input.field_name]
                          "
                          type="is-info is-light placeholder"
                          :closable="false"
                          >{{ input.field_placeholder }}</b-notification
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template
                v-if="input.field_type === 'multifile' && input.is_active"
              >
                <div
                  :class="
                    errors[input.field_name] &&
                    errors[input.field_name].length > 0
                      ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                      : 'columns is-mobile is-variable is-3 is-multiline'
                  "
                  :key="input.id"
                >
                  <div class="column ym-column is-12">
                    <div class="ym-label">
                      <label for="f_company_full">{{ input.name }}</label>
                    </div>
                  </div>
                  <div class="column ym-column is-12">
                    <div class="field">
                      <div class="control has-icons-right is-clearfix">
                        <ValidationProvider
                          :rules="input.is_required ? 'required' : ''"
                          :name="input.field_name"
                        >
                          <b-upload
                            v-model="temp[`${input.field_name}_temp`]"
                            multiple
                            drag-drop
                            @input="fileToBase64($event, input.field_name)"
                            class="file-label"
                            :key="input.id"
                            style="margin-bottom: 1rem"
                          >
                            <section class="section" style="padding: 3rem">
                              <div class="content has-text-centered">
                                <p>
                                  <b-icon
                                    icon="upload"
                                    size="is-large"
                                    style="position: relative"
                                  />
                                </p>
                                <p>
                                  Upuść pliki tutaj lub kliknij, aby przesłać
                                </p>
                              </div>
                            </section>
                          </b-upload>
                          <div
                            class="tags"
                            v-if="temp[`${input.field_name}_temp`]"
                          >
                            <span
                              v-for="(file, index) in temp[
                                `${input.field_name}_temp`
                              ]"
                              :key="index"
                              class="tag is-primary"
                            >
                              {{ file.name }}
                              <button
                                class="delete is-small"
                                type="button"
                                @click="
                                  deleteDropFile(
                                    `${input.field_name}_temp`,
                                    index,
                                    `${input.field_name}`
                                  )
                                "
                              ></button>
                            </span>
                          </div>
                        </ValidationProvider>
                        <span
                          v-if="
                            errors[input.field_name] &&
                            errors[input.field_name].length > 0
                          "
                          class="help is-danger"
                          >{{ errors[input.field_name][0] }}</span
                        >
                        <b-notification
                          v-if="
                            input.field_placeholder !== '' &&
                            !form[input.field_name]
                          "
                          type="is-info is-light placeholder"
                          :closable="false"
                          >{{ input.field_placeholder }}</b-notification
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </template>
          <div class="level ym-toptitle" style="margin-top: 48px">
            <div class="level-left">&nbsp;</div>
            <div class="buttons level-right">
              <button class="button is-medium is-primary">
                <icon name="check"></icon><span>Wyślij</span>
              </button>
            </div>
          </div>
        </section>
      </form>
      <b-modal
        v-model="isModalActive"
        v-if="formSettings && formSettings.calendar_code !== ''"
        :width="720"
        scroll="keep"
      >
        <div class="card">
          <div class="card-content">
            <div
              class="content content-iframe"
              v-html="formSettings.calendar_code"
            ></div>
          </div>
        </div>
      </b-modal>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FixedHeader from "vue-fixed-header";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Multiselect from "vue-multiselect";

export default {
  name: "FormComp",
  components: {
    FixedHeader,
    ValidationObserver,
    ValidationProvider,
    Multiselect,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    formSettings: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      ready: false,
      inputs: null,
      form: {
        form_template_id: this.id,
      },
      temp: {},
      alert: false,
      isModalActive: false,
    };
  },
  computed: {
    ...mapGetters({
      calendar: "calendar",
      infoAfterSubmit: "infoAfterSubmit",
    }),
  },
  created() {
    this.getForm(this.id).then((resp) => {
      this.inputs = resp.data;
      for (const key in this.inputs) {
        if (Object.hasOwnProperty.call(this.inputs, key)) {
          const element = this.inputs[key];
          if (element.field_type === "multiselect") {
            this.form[element.field_name] = [];
          }
        }
      }
      this.ready = true;
    });
  },
  methods: {
    ...mapActions({
      getForm: "form/getForm",
      postForm: "form/postForm",
    }),
    async fileToBase64(e, input_name) {
      const toBase64 = (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
      if (e.length) {
        this.form[`${input_name}`] = [];
        this.form[`${input_name}_name`] = [];
        for (const file of e) {
          this.form[`${input_name}`].push(await toBase64(file));
          this.form[`${input_name}_name`].push(file.name);
        }
      } else {
        this.form[input_name] = await toBase64(e);
        this.form[`${input_name}_name`] = e.name;
      }
    },
    deleteDropFile(where, index, name) {
      this.form[name].splice(index, 1);
      this.form[`${name}_name`].splice(index, 1);
      this.temp[where].splice(index, 1);
    },
    onSubmitScroll() {
      this.$refs.formObserver.validate().then((success) => {
        if (!success) {
          for (const key of Object.keys(this.$refs.formObserver.fields)) {
            if (this.$refs.formObserver.fields[key].invalid) {
              const y =
                this.$refs.formObserver.refs[key].$el.getBoundingClientRect()
                  .top;
              this.$refs.formObserver.refs[key].$el.scrollIntoView({
                top: y,
                behavior: "smooth",
              });
              return;
            }
          }
        }

        this.onSubmit();
      });
    },
    onSubmit() {
      const payload = {
        ...this.form,
        operator_confirmed: 1,
      };
      this.postForm(payload)
        .then(() => {
          this.alert = true;
          this.form = {};
          this.temp = {};
        })
        .catch((error) => {
          // this.form = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.file-cta,
.upload:hover .file-cta {
  background-color: #000;
  color: #fff;
}
.file-name {
  width: 100%;
  max-width: 100%;
}
.select {
  width: 100%;
}
.ym-radios {
  flex-wrap: wrap;
  & label {
    margin: 0;
    width: auto;
    text-align: center;
    flex: auto;
  }
}
.control::v-deep .multiselect__tags {
  border-radius: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 0 2px 0 rgba(0, 0, 0, 0.2);
  min-height: 48px;
}
.control::v-deep .multiselect__input {
  border: none !important;
}
.control::v-deep input:focus {
  box-shadow: none !important;
}
.alert-info {
  padding: 4rem 0;
  text-align: center;

  & h1 {
    color: #000;
    font-size: 2rem;
    font-weight: 800;
    margin-bottom: 2rem;
  }

  & p {
    margin-bottom: 2rem;
  }

  & button {
    margin: 0 auto;
  }
}
</style>

<style>
.content-iframe {
  position: relative;
  height: 0;
  padding-bottom: 50%;
}

.content-iframe iframe,
.content-iframe object,
.content-iframe embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 851px) {
  .content-iframe {
    min-width: 80vw;
    min-height: calc(100vh - 210px);
  }
}
</style>

<template>
  <div v-if="Object.keys(order).length > 0 && dictionary && Object.keys(dictionary).length > 0" class="details">
    <div class="columns is-multiline is-variable is-3">
      <div class="column is-4" v-if="order.number">
        <p class="label-small">Numer zlecenia</p>
        <p class="label is-uppercase">{{ order.number }}</p>
      </div>
      <div class="column is-4" v-if="order.created_at">
        <p class="label-small">Data przyjęcia</p>
        <p class="label is-uppercase">{{ order.created_at }}</p>
      </div>
      <div class="column is-4" v-if="order.status_id">
        <p class="label-small">Status zlecenia</p>
        <p class="label is-uppercase" :style="{ color: order.status.colour }">{{ order.status.name }}</p>
      </div> 
      <div class="column is-4" v-if="order.operator_name">
        <p class="label-small"><span v-if="order.operator_confirmed">Zlecenie przyjął</span><span v-else>Zlecenie do potwierdzenia przez:</span></p>
        <p class="label is-uppercase">{{ order.operator_name }}</p>
      </div>
      <div class="column is-4" v-if="order.technical_name">
        <p class="label-small">{{ dictionary['table_order_technical_name_field_label'] }}</p>
        <p class="label is-uppercase">{{ order.technical_name }}</p>
      </div>
      <div class="column is-4" v-if="order.repared_at">
        <p class="label-small">Data naprawy</p>
        <p class="label is-uppercase">{{ order.repared_at }}</p>
      </div>    
      <div class="column is-12">
        <hr />
      </div> 
      <div class="column is-4" v-if="order.customer_name">
        <p class="label-small">{{ dictionary['table_order_customer_name_field_label'] }}</p>
        <p class="label is-uppercase">{{ order.customer_name }}</p>
      </div>
      <div class="column is-4" v-if="order.customer_address">
        <p class="label-small">Adres</p>
        <p class="label is-uppercase">{{ order.customer_address }}<br/>{{ order.customer_postcode }} {{ order.customer_city }}</p>
      </div>    
      <div class="column is-4" v-if="order.customer_phone">
        <p class="label-small">Telefon</p>
        <p class="label is-uppercase">{{ order.customer_phone }}</p>
      </div>
      <div class="column is-4" v-if="order.customer_email">
        <p class="label-small">Email</p>
        <p class="label is-uppercase">{{ order.customer_email }}</p>
      </div>
      <div class="column is-4" v-if="order.customer_nip">
        <p class="label-small">NIP</p>
        <p class="label is-uppercase">{{ order.customer_nip }}</p>
      </div>
      <div class="column is-12">
        <hr />
      </div>    
      <div class="column is-4" v-if="order.device_type">
        <p class="label-small">{{ dictionary['table_order_device_type_field_label'] }}</p>
        <p class="label is-uppercase">{{ order.device_type }}</p>
      </div>
      <div class="column is-4" v-if="order.device_name">
        <p class="label-small">{{ dictionary['table_order_device_name_field_label'] }}</p>
        <p class="label is-uppercase">{{ order.device_name }}</p>
      </div>
      <div class="column is-4" v-if="order.device_serial">
        <p class="label-small">{{ dictionary['table_order_device_serial_field_label'] }}</p>
        <p class="label is-uppercase">{{ order.device_serial }}</p>
      </div>
      <div class="column is-4">
        <p class="label-small">{{ dictionary['table_order_is_warranty_field_label'] }}</p>
        <p class="label is-uppercase">{{ order.is_warranty ? 'TAK' : 'NIE' }}</p>
      </div>
      <div class="column is-4" v-if="order.warranty_status_id !== 0">
        <p class="label-small">{{ dictionary['table_order_warranty_status_field_label'] }}</p>
        <p class="label is-uppercase">{{ warrantyName }}</p>
      </div>
      <div class="column is-4" v-if="order.service_type_id !== 0">
        <p class="label-small">{{ dictionary['table_order_service_type_name_field_label'] }}</p>
        <p class="label is-uppercase">{{ order.service_type_name }}</p>
      </div>
      <div class="column is-4" v-if="order.device_service_work">
        <p class="label-small">{{ dictionary['table_order_device_service_work_field_label'] }}</p>
        <p class="label is-uppercase">{{ order.device_service_work }}</p>
      </div>
      <div class="column is-4" v-if="order.service_time">
        <p class="label-small">{{ dictionary['table_order_service_time_field_label'] }}</p>
        <p class="label is-uppercase">{{ order.service_time }}</p>
      </div>
      <div class="column is-12">
        <hr />
      </div> 
      <div class="column is-12" v-if="order_service.length > 0">
        <b>{{ dictionary['table_order_is_service_field_label'] }}</b><br /><br />
        <div class="ym-table ym-table__info">
          <div v-for="service in order_service" :key="service.id" class="ym-tableItem ym-tableItem__prl">
            <div>{{ service.category }}</div>
            <div>{{ service.name }}</div>
            <div>{{ service.minutes }} min</div>
            <div>{{ service.price }} zł</div>
          </div>
          <div class="ym-tableItem ym-tableItem__prl">
            <div>&nbsp;</div>
            <div><b>Podsumowanie</b></div>
            <div>{{ orderServiceMinutes(order_service) }} min</div>
            <div>{{ orderServiceCash(order_service) }} zł</div>
          </div>
        </div>
      </div>     
      <div class="column is-4" v-if="order.service_price">
        <p class="label-small">{{ dictionary['table_order_service_price_field_label'] }}</p>
        <p class="label is-uppercase">{{ order. service_price }} zł</p>
      </div>
      <div class="column is-12">
        <hr />
      </div> 
      <div class="column is-12" v-if="order.service_fault">
        <p class="label-small">{{ dictionary['table_order_service_fault_field_label'] }}</p>
        <p class="label is-uppercase">{{ order.service_fault }}</p>
      </div>  
      <div class="column is-12" v-if="order.service_description">
        <p class="label-small">{{ dictionary['table_order_service_description_field_label'] }}</p>
        <p class="label is-uppercase">{{ order.service_description }}</p>
      </div>     
      <div class="column is-12" v-if="order.technical_notes">
        <p class="label-small">{{ dictionary['table_order_technical_notes_field_label'] }}</p>
        <p class="label is-uppercase">{{ order.technical_notes }}</p>
      </div>
      <div class="column is-12" v-if="order.technical_service">
        <p class="label-small">{{ dictionary['table_order_technical_service_field_label'] }}</p>
        <p class="label is-uppercase">{{ order.technical_service }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions,mapGetters} from 'vuex'

export default {
  name: 'OrderDetails',
  data() {
    return {
      order: {},
      order_service: {},
    }
  },
  created() {
    this.getOrderDetails(this.$route.params.token).then((resp) => {
      this.order = resp.order
      this.order_service = resp.order_service
    })
  },
  computed: {
    ...mapGetters({
      dictionary: 'dictionary',
    }),       
  },
  methods: {
    ...mapActions({
      getOrderDetails: 'getOrderDetails',
    }),
    orderServiceMinutes(obj) {
      let sum = 0
      obj.forEach(function(item){
        sum += item.minutes;
      })
      return sum
    },
    orderServiceCash(obj) {
      let sum = 0
      obj.forEach(function(item){
        sum += item.price;
      })
      return sum
    },
  },
}
</script>

<style scoped>
.details {
  padding: 48px 24px;
  background-color: #fff;
}
.column {
  margin-bottom: 24px;
}
</style>